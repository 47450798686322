@import '../../scss/colors';

@font-face {
  font-family: 'SSF2-Bold';
  src: url('./fonts/SSF2-Bold.woff2') format('woff2'), url('./fonts/SSF2-Bold.woff') format('woff');
}

.filterStyle {
  color-interpolation-filters: sRGB;
}

@mixin glowStyle {
  fill-rule: evenodd;
  stroke: none;
}

.innerGlowStyle {
  @include glowStyle;
  fill-opacity: 1;
}

.middleGlowStyle {
  @include glowStyle;
  fill-opacity: 1;
  opacity: 0.33700005;
}

.outerGlowStyle {
  @include glowStyle;
  fill-opacity: 0.19607843;
}

.bulbStyle {
  display: inline;
  fill: $white;
  stroke: none;
}

.textStyle {
  fill: $white;
  font-family: 'SSF2-Bold';
  font-size: 133px;
  line-height: 125%;
  stroke: none;
}
