.tip {
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 0 5px;
}

.tipBox {
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  margin: .5em;
  min-height: 3.5rem;
  min-width: 80%;
  padding: 10px 30px;
  text-align: center;
  vertical-align: middle;
}

.tipButton {
  align-items: center;
  height: 3rem;
}

#tip-text-1 {
  animation: fadein 4s ease-in forwards;
}

#tip-text-2 {
  animation: fadein 4s ease-in forwards;
  animation-delay: 4s;
  opacity: 0;
}

#tip-text-3 {
  animation: fadein 4s ease-in forwards;
  animation-delay: 8s;
  opacity: 0;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
