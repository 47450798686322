@import '../scss/breaks';

#navbarBrand{
  height: 2.2em;
  padding: 0;
}


@media (min-width: $break-sm) {
  #navbarBrand{
    margin-right: 1em;
  }
}

@media (min-width: $break-md) {
  #navbarBrand {
    margin-right: 3em;
  }
}
