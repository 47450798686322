@import '../scss/breaks';

#TOC {
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: 0;
  }
}

.dropownMenu {
  max-height: 8em;
  overflow-y: scroll;
}


@media (min-width: $break-sm) {
  .dropownMenu {
    max-height: 10em;
  }
}

@media (min-width: $break-md) {
  .dropownMenu {
    max-height: 16em;
  }
}

@media (min-width: $break-lg) {
  .dropownMenu {
    max-height: 20em;
  }
}

@media (min-width: $break-xl) {
  .dropownMenu {
    max-height: 24em;
  }
}
