@import '../../scss/colors';

.google {
  font-family: Roboto;
  font-weight: 400;
  padding: 0;
  text-align: left;
  width: 100%;

  &,
  &:hover,
  &:active {
    background-color: $google;
    border: 0;
    color: $google-text;
  }
}

.facebook {
  text-align: left;
  width: 100%;

  &,
  &:hover,
  &:active {
    background-color: $facebook;
    border: 0;
  }
}

.divider {
  margin-left: 5%;
  width: 90%;
}
