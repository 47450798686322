.logout {
  &,
  &:active,
  &:focus,
  &:hover {
    border: 0;
  }

  &:focus {
    box-shadow: 0 0 0 transparent;
  }
}
