@import '../scss/colors';

.bullet {
  list-style: none;
  margin: .5em 0 .5em 1em;

  &::before {
    color: $lightgray;
    content: '\2022';
    display: inline-block;
    font-size: 1.2em;
    font-weight: bold;
    margin-left: -1em;
    width: 1em;
  }
}

.roman {
  list-style-type: upper-roman;
  margin: .5em 0 .5em 1em;
}

.decimal {
  list-style-type: decimal;
  margin: .5em 0 .5em 2em;
}

.none {
  list-style: none;
  margin: .5em 0 .5em 1em;
}

.double {
  list-style: none;
  margin: .5em 0 .5em 2em;
}

.decimal2 {
  list-style: none;
  margin: .5em 0 .5em -0.5em;
}

.dash {
  list-style: none;
  margin: .5em 0 .5em 2em;

  &::before {
    content: '-';
    display: inline-block;
    font-size: 1.2em;
    margin-left: -1em;
    width: 1em;
  }
}

.dash2 {
  list-style: none;
  margin: .5em 0 .5em 3em;

  &::before {
    content: '-';
    display: inline-block;
    font-size: 1.2em;
    margin-left: -1em;
    width: 1em;
  }
}
