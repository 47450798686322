
$border: #000;

.exercise {
  margin: 1em 0;
}

.question {
  border: .15em solid $border;
  border-radius: .5em;
  font-size: 1.2em;
  font-weight: 600;
  padding: .35em;
  text-align: left;
  width: 100%;
}

.icons {
  display: inline-flex;
  margin-left: .5em;
  min-width: 15em;
}

.exButton {
  align-items: center;
  height: 2.7rem;
  margin: .1rem;
  padding: .2rem;
}

.difficulty {
  font-size: 1.3rem;
  font-weight: 500;
  padding: .1rem;
  vertical-align: bottom;
}
