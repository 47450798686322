@import '../scss/breaks';

#userNav {
  margin-left: 0;
}

#userId {
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: 0;
  }
}


@media (min-width: $break-sm) {
  #userNav {
    margin-left: 1em;
  }
}
