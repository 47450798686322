@import '../scss/breaks';

#logo {
  height: 5em;
}

#logoBox {
  margin-bottom: 6em;
  margin-top: 3em;
}

#subLogo {
  font-size: .8em;
  margin-top: -1.7em;
}

@media (min-width: $break-sm)  {
  #logo {
    height: 8em;
  }

  #logoBox {
    margin-top: 4em;
  }

  #subLogo {
    font-size: 1.1em;
    margin-top: -1.7em;
  }
}

@media (min-width: $break-md) {
  #logo {
    height: 10em;
  }

  #subLogo {
    font-size: 1.2em;
    margin-top: -2em;
  }

}
