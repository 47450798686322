.tip {
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 0 5px;
}

.tipBox {
  align-items: center;
  border-radius: 10px;
  margin: .5em;
  min-height: 3.5rem;
  min-width: 80%;
  padding: 10px ;
  text-align: center;
  vertical-align: middle;
}

.tipButton {
  align-items: center;
  height: 2.7rem;
}
