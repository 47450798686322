@import '../colors';

//Navbar
$navbar-light-color: $white;
$navbar-light-hover-color: $white;
$navbar-light-active-color: $white;

//Dropdown
$dropdown-link-color: $white;
$dropdown-link-active-color: $white;
$dropdown-link-hover-color: $white;
$dropdown-link-active-bg: transparent;
$dropdown-link-hover-bg: transparent;

//Text
$font-family-sans-serif: 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-base: $font-family-sans-serif;

//Forms
$input-btn-focus-width:       .2rem;
$input-btn-focus-color: $accent;

@import 'node_modules/bootstrap/scss/bootstrap';
