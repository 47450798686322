
@import './scss/colors';
@import './scss/breaks';

.app {
  min-width: 640px;
  padding-left: 10px;
  padding-right: 10px;
  width: 95%;
}

.accent {
  color: $accent;
  fill: $accent;
}

.main {
  color: $main;
  fill: $accent;
}

.white {
  color: $white;
  fill: $white;
}

.gray {
  color: $gray;
}

ul {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

@media (min-width: $break-md) {
  .app {
    width: 98%;
  }
}
